<template>
    <div class="link-view">
        <v-card
            v-if="item"
            :href="item.url"
            :flat="flat"
            :tile="tile"
            :outlined="outlined"
            :hover="hover"
            target="_blank"
            class="pa-4 d-flex theme-hover-bg"
        >
            <media-title :item="item" class="flex-grow-1 text-truncate"/>
            <w-icon value="NEXT" color="primary"/>
        </v-card>
    </div>
</template>

<script>
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'
import MediaTitle from '@apps/media/components/MediaPreview'

export default {
    name: 'LinkView',
    components: { MediaTitle },
    mixins: [ mediaViewMixin ],
    props: {
        tile: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        hover: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang=scss>
    .link-view {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>
