<template>
    <div
        v-if="item"
        class="d-flex align-center"
    >
        <div class="d-flex align-center justify-center mr-4" :style="{width: '28px'}">
            <media-icon
                :item="item"
                :size="sizeIcon"
            />
        </div>
        <div class="text-truncate">
            <div class="text-sm text--primary font-weight-bold text-truncate">{{ item.title }}</div>
            <div class="caption">{{ typeLabel }}</div>
        </div>
    </div>
</template>

<script>
import MediaIcon from '@apps/media/components/MediaIcon'
import { isImage, mediaTypeLabel } from '@apps/media/tools'
import { mediaTypes } from '@apps/media/tools/config'
export default {
    name: 'MediaPreview',
    components: { MediaIcon },
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    computed: {
        sizeIcon() {
            if(this.item.type === mediaTypes.FOLDER) {
                return 28
            }

            if(isImage(this.item.src)) {
                return 30
            }

            return 20
        },
        typeLabel() {
            const label = mediaTypeLabel(this.item.src)

            return label || this.$trans(`media.types.${this.item.type}`)
        }
    }
}
</script>

<style lang=scss>

</style>
